<template>
  <div>
    <div v-for="file of files" :key="file.encodedName">
      {{ file.name }}
      <a :href="$route.path + '/' + file.encodedName">
        {{ $route.path + "/" + file.encodedName }}
      </a>
      <br />
      <img
        :alt="file.name"
        :src="$route.path + '/' + file.encodedName"
        class="anleitung"
      />
    </div>
    <router-link
      v-for="dir of dirs"
      :key="dir.encodedName"
      :to="$route.path + '/' + dir.encodedName"
    >
      {{ dir.name }}
      <br />
    </router-link>
  </div>
</template>

<script>
import index from "./index.json"

export default {
  computed: {
    files() {
      return Object.values(this.dirInfo.children).filter(
        file => file.type !== "directory"
      )
    },
    dirs() {
      return Object.values(this.dirInfo.children).filter(
        file => file.type === "directory"
      )
    },
    dirInfo() {
      let path = this.$route.params.catchAll
      let segments = path.split("/").filter(e => e !== "")

      if (segments.length === 0) return index
      return this.walk(segments, index)
    }
  },
  methods: {
    walk(segments, parentDir) {
      let segment = segments.shift(0)
      let dir = parentDir.children[segment]

      if (segments.length !== 0) {
        return this.walk(segments, dir)
      } else {
        return dir
      }
    }
  }
}
</script>

<style scoped>
.anleitung {
  max-width: 500px;
  max-height: 500px;
}
</style>
